<template>
  <v-card class="accounting-document">
    <v-toolbar color="grey darken-3" dark elevation="1" height="52">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-3 pb-1">
      <div class="conducted-box d-flex">
        <v-alert
            dense
            text
            class="mb-0 py-1"
            :color="conducted ? 'success' : 'secondary'"
            :type="conducted ? 'success' : 'warning'"
            style="flex: 1"
        >
          {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
        </v-alert>
      </div>
      <div class="document-header">
        <div class="document-header-row pt-2 pb-1">
          <div style="flex: 0 0 180px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true"></date_input>
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"></string_input>
          </div>
          <div style="flex: 0 0 320px; margin-right: 16px">
            <select_input
                v-model="operation"
                :import-getter="{ name: 'commissioning_document_operation' }"
                :input-value-as-value="false"
                clearable
                label="Операція: "
                @selectChange="onOperationChange"
            />
          </div>
        </div>
        <div class="mt-2">
          <v-tabs class="document-small-tabs" color="success">
            <v-tab>
              Основний засіб
            </v-tab>
            <v-tab>
              Амортизація/Знос
            </v-tab>

            <v-tab-item class="pt-2">
              <div class="document-header-row  pt-1 pb-3" v-if="operation === 'fixed_asset'">
                <div style="flex: 0 0 342px; margin-right: 16px">
                  <select_input
                      v-model="nomenclature_id"
                      :computed-action="'SELECT_NOMENCLATURE_LIST_ELEMENTS'"
                      :computed-getter="'get_nomenclature_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      label="Номенклатура"
                      :show-select-on-focus="false"
                      :action-props="{is_service: false}"
                      :select-button-object="{
                                name: 'NOMENCLATURE_ELEMENTS',
                                group: 'NOMENCLATURE_GROUP',
                                hierarchy: true,
                                only_groups: false,
                                title: 'Список номенклатури',
                                element_title: 'Номенклатура',
                                action_props: {is_service: false},
                                show: true
                              }"
                  />
                </div>
                <div style="flex: 0 0 316px; margin-right: 16px">
                  <select_input
                      v-model="invest_chart_of_account_id"
                      :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                      :computed-getter="'get_chart_of_accounts_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      label="Рахунок інвестицій"
                      :show-select-on-focus="false"
                      :action-filter="'@field=type@;@start-with=S@152@@@;@field=type@;@start-with=S@153'"
                      :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                action_filter: '@field=code@;@start-with=S@152@@@;@field=code@;@start-with=S@153',
                                show: true
                              }"
                      @selectChange="onInvestChartChange"
                  />
                </div>
              </div>
              <div class="document-header-row pt-1 pb-3">
                <div style="flex: 0 0 342px; margin-right: 16px">
                  <select_input
                      v-model="fixed_asset_id"
                      :computed-action="'SELECT_FIXED_ASSETS_LIST_ELEMENTS'"
                      :computed-getter="'get_fixed_assets_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      label="Основний засіб"
                      :show-select-on-focus="false"
                      :select-button-object="{
                                name: 'FIXED_ASSETS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список основних засоби',
                                element_title: 'Рахунок',
                                show: true
                              }"
                  />
                </div>
                <div style="flex: 0 0 316px; margin-right: 16px">
                  <select_input
                      v-model="chart_of_account_id"
                      :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                      :computed-getter="'get_chart_of_accounts_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      label="Рахунок осн.засобу або необ.мат. активу"
                      :show-select-on-focus="false"
                      :action-filter="'@field=type@;@start-with=S@10@@@;@field=type@;@start-with=S@11'"
                      :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                action_filter: '@field=code@;@start-with=S@10@@@;@field=code@;@start-with=S@11',
                                show: true
                              }"
                      @selectChange="onChartOfAccountIdChange"
                  />
                </div>
                <div style="flex: 0 0 240px">
                  <v-checkbox hide-details
                              label="Це малоцінний актив"
                              class="mt-5 pt-0"
                              color="grey darken-1"
                              v-model="its_low_cost_item"
                  />
                </div>
              </div>
              <div class="document-header-row pt-1 pb-3"  v-if="operation === 'fixed_asset'">
                <div style="flex: 0 0 342px; margin-right: 16px">
                  <select_input
                      v-model="warehouse_id"
                      :computed-action="'SELECT_WAREHOUSE_LIST_ELEMENTS'"
                      :computed-getter="'get_warehouse_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      label="Склад"
                      :show-select-on-focus="false"
                      :sync-action-props="true"
                      :select-button-object="{
                                name: 'WAREHOUSE_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список складів',
                                element_title: 'Склад',
                                show: true,
                              }"
                  />
                </div>
                <div style="flex: 0 0 316px; margin-right: 16px">
                  <select_input
                      v-model="subdivision_id"
                      :computed-action="'SELECT_SUBDIVISION_LIST_ELEMENTS'"
                      :computed-getter="'get_subdivision_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      label="Підрозділ"
                      :show-select-on-focus="false"
                      :select-button-object="{
                              name: 'SUBDIVISION_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'Список підрозділ',
                              element_title: 'Підрозділ',
                              show: true
                            }"
                  />
                </div>
              </div>
              <div class="document-header-row pt-1 pb-3"  v-if="operation === 'inventory'">
                <div style="flex: 0 0 342px; margin-right: 16px">
                  <select_input
                      v-model="subdivision_id"
                      :computed-action="'SELECT_SUBDIVISION_LIST_ELEMENTS'"
                      :computed-getter="'get_subdivision_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      label="Підрозділ"
                      :show-select-on-focus="false"
                      :select-button-object="{
                              name: 'SUBDIVISION_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'Список підрозділ',
                              element_title: 'Підрозділ',
                              show: true
                            }"
                  />
                </div>
                <div style="flex: 0 0 316px; margin-right: 16px">
                  <select_input
                      v-model="response_person_id"
                      :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                      :computed-getter="'get_personal_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      label="Відповідальна особа"
                      :show-select-on-focus="false"
                      :action-filter="'@field=type@;@start-with=S@full_time'"
                      :required="true"
                      :select-button-object="{
                            name: 'PERSONAL_ELEMENTS',
                            group: '',
                            hierarchy: false,
                            only_groups: false,
                            title: 'Список працівників',
                            element_title: 'Працівник',
                            action_filter: '@field=person_type@;@start-with=S@full_time',
                            show: true
                          }"
                  />
                </div>
              </div>
              <div class="document-header-row pt-1 pb-3" v-if="operation === 'fixed_asset'">
                <div style="flex: 0 0 342px; margin-right: 16px">
                  <select_input
                      v-model="response_person_id"
                      :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                      :computed-getter="'get_personal_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      label="Відповідальна особа"
                      :show-select-on-focus="false"
                      :action-filter="'@field=type@;@start-with=S@full_time'"
                      :required="true"
                      :select-button-object="{
                            name: 'PERSONAL_ELEMENTS',
                            group: '',
                            hierarchy: false,
                            only_groups: false,
                            title: 'Список працівників',
                            element_title: 'Працівник',
                            action_filter: '@field=person_type@;@start-with=S@full_time',
                            show: true
                          }"
                  />
                </div>
              </div>
              <div class="document-header-row pt-1 pb-3" v-if="operation === 'inventory'">
                <div style="flex: 0 0 342px; margin-right: 16px">
                  <select_input
                      v-model="profit_chart_of_account_id"
                      :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                      :computed-getter="'get_chart_of_accounts_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      label="Рахунок доходу: "
                      :show-select-on-focus="false"
                      :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                show: true
                              }"
                      @selectChange="afterHeaderPayChartAccountChange"
                  />
                </div>
                <div style="flex: 0 0 316px; margin-right: 16px;" v-if="profit_chart_of_account_1_type">
                  <select_input
                      v-model="profit_chart_of_account_1_value"
                      :computed-action="subconto_type_select_setting[profit_chart_of_account_1_type].computed_action"
                      :computed-getter="subconto_type_select_setting[profit_chart_of_account_1_type].computed_getter"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      :show-select-on-focus="false"
                      :label="subconto_type_select_setting[profit_chart_of_account_1_type].label"
                      :label_style="'color: var(--v-success-base); font-weight: 500;'"
                      :required="true"
                      :clearable="true"
                      :select-button-object="{
                                name: subconto_type_select_setting[profit_chart_of_account_1_type].select_button_object.name,
                                group: subconto_type_select_setting[profit_chart_of_account_1_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[profit_chart_of_account_1_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[profit_chart_of_account_1_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[profit_chart_of_account_1_type].select_button_object.title,
                                element_title: subconto_type_select_setting[profit_chart_of_account_1_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                      @selectChange="afterHeaderDetail1Change"
                  />
                </div>
              </div>
              <div class="document-header-row pt-1 pb-3" v-if="operation === 'inventory' && profit_chart_of_account_2_type">
                <div style="flex: 0 0 342px; margin-right: 16px">
                </div>
                <div style="flex: 0 0 316px; margin-right: 16px;" v-if="profit_chart_of_account_2_type">
                  <select_input
                      v-model="profit_chart_of_account_2_value"
                      :computed-action="subconto_type_select_setting[profit_chart_of_account_2_type].computed_action"
                      :computed-getter="subconto_type_select_setting[profit_chart_of_account_2_type].computed_getter"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      :show-select-on-focus="false"
                      :label="subconto_type_select_setting[profit_chart_of_account_2_type].label"
                      :label_style="'color: var(--v-success-base); font-weight: 500;'"
                      :required="true"
                      :clearable="true"
                      :select-button-object="{
                                name: subconto_type_select_setting[profit_chart_of_account_2_type].select_button_object.name,
                                group: subconto_type_select_setting[profit_chart_of_account_2_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[profit_chart_of_account_2_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[profit_chart_of_account_2_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[profit_chart_of_account_2_type].select_button_object.title,
                                element_title: subconto_type_select_setting[profit_chart_of_account_2_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                      @selectChange="afterHeaderDetail1Change"
                  />
                </div>
              </div>
              <div class="document-header-row pt-1 pb-3" v-if="operation === 'inventory' && profit_chart_of_account_3_type">
                <div style="flex: 0 0 342px; margin-right: 16px">
                </div>
                <div style="flex: 0 0 316px; margin-right: 16px;" v-if="profit_chart_of_account_3_type">
                  <select_input
                      v-model="profit_chart_of_account_3_value"
                      :computed-action="subconto_type_select_setting[profit_chart_of_account_3_type].computed_action"
                      :computed-getter="subconto_type_select_setting[profit_chart_of_account_3_type].computed_getter"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      :show-select-on-focus="false"
                      :label="subconto_type_select_setting[profit_chart_of_account_3_type].label"
                      :label_style="'color: var(--v-success-base); font-weight: 500;'"
                      :required="true"
                      :clearable="true"
                      :select-button-object="{
                                name: subconto_type_select_setting[profit_chart_of_account_3_type].select_button_object.name,
                                group: subconto_type_select_setting[profit_chart_of_account_3_type].select_button_object.group,
                                hierarchy: subconto_type_select_setting[profit_chart_of_account_3_type].select_button_object.hierarchy,
                                only_groups: subconto_type_select_setting[profit_chart_of_account_3_type].select_button_object.only_groups,
                                title: subconto_type_select_setting[profit_chart_of_account_3_type].select_button_object.title,
                                element_title: subconto_type_select_setting[profit_chart_of_account_3_type].select_button_object.element_title,
                                show: true,
                                clearable: true
                              }"
                      @selectChange="afterHeaderDetail1Change"
                  />
                </div>
              </div>
              <div class="document-header-row pt-1 pb-3" v-if="operation === 'inventory'">
                <div style="flex: 0 0 342px; margin-right: 16px">
                  <number_input label="Вартість ОС"
                                v-model="profit_sum"
                                v-decimal
                                required />
                </div>
              </div>
            </v-tab-item>
            <v-tab-item class="pt-2">
              <div class="document-header-row pt-1 pb-3">
                <div style="flex: 0 0 342px; margin-right: 16px">
                  <select_input
                      v-model="amortisation_chart_of_account_id"
                      :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                      :computed-getter="'get_chart_of_accounts_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      label="Рахунок амортизації"
                      :show-select-on-focus="false"
                      :action-filter="'@field=type@;@start-with=S@13@@@;@field=type@;@start-with=S@13'"
                      :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                action_filter: '@field=code@;@start-with=S@13@@@;@field=code@;@start-with=S@13',
                                show: true
                              }"
                  />
                </div>
                <div style="flex: 0 0 316px"
                     v-if="amortisation_calc_type !== 'fifty_to_fifty' && amortisation_calc_type !== 'hundred'">
                  <v-checkbox hide-details
                              label="Нараховувати амортизацію/знос"
                              class="mt-5 pt-0"
                              color="grey darken-1"
                              v-model="calc_amortisation"
                  />
                </div>
              </div>
              <div class="document-header-row pt-1 pb-3">
                <div style="flex: 0 0 342px; margin-right: 16px">
                    <select_input
                        v-if="its_low_cost_item"
                        v-model="fixed_asset_group"
                        :import-getter="{ name: 'fixed_assets_group_only_low_cost' }"
                        :input-value-as-value="false"
                        :sync-import-module="true"
                        clearable
                        label="Група ОС: "
                    />
                    <select_input
                        v-else
                        v-model="fixed_asset_group"
                        :import-getter="{ name: 'fixed_assets_group_only_fixed' }"
                        :sync-import-module="true"
                        :input-value-as-value="false"
                        clearable
                        label="Група ОС: "
                        @selectChange="onFixedAssetGroupChange"
                    />
                  </div>
                <div style="flex: 0 0 316px; margin-right: 16px">
                  <select_input
                      v-model="revaluation_chart_of_account_id"
                      :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                      :computed-getter="'get_chart_of_accounts_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      label="Рахунок дооцінок"
                      :show-select-on-focus="false"
                      :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                show: true
                              }"
                  />
                </div>
              </div>
              <div class="document-header-row pt-1 pb-3">
                <div style="flex: 0 0 342px; margin-right: 16px">
                  <select_input
                      v-if="its_low_cost_item"
                      v-model="amortisation_calc_type"
                      :import-getter="{ name: 'amortisation_calc_type_low_cost' }"
                      :input-value-as-value="false"
                      :sync-import-module="true"
                      clearable
                      label="Спосіб нарахування амортизації: "
                  />
                  <select_input
                      v-else
                      v-model="amortisation_calc_type"
                      :import-getter="{ name: 'amortisation_calc_type_fixed' }"
                      :input-value-as-value="false"
                      :sync-import-module="true"
                      clearable
                      label="Спосіб нарахування амортизації: "
                  />
                </div>
                <div style="flex: 0 0 316px; margin-right: 16px">
                  <select_input
                      v-model="amortisation_calc_way"
                      :computed-action="'SELECT_AMORTISATION_CALC_WAY_LIST_ELEMENTS'"
                      :computed-getter="'get_amortisation_calc_way_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="0"
                      label="Відображення витрат по амортизації"
                      :show-select-on-focus="false"
                      :required="true"
                      :select-button-object="{
                            name: 'AMORTISATION_CALC_WAY_ELEMENTS',
                            group: '',
                            hierarchy: false,
                            only_groups: false,
                            title: 'Список відображень витрат по амортизації',
                            element_title: 'Відобрадення витрати по амортизації',
                            show: true
                          }"
                  />
                </div>
              </div>
              <div class="document-header-row pt-1 pb-3" v-if="!its_low_cost_item">
                <div style="flex: 0 0 342px; margin-right: 16px">
                  <number_input label="Строк корисного використання"
                                v-model.number="useful_life_period"
                                v-integer/>
                </div>
                <div style="flex: 0 0 342px; margin-right: 16px">
                  <number_input label="Ліквідаційна вартість"
                                v-model.number="liquidation_value"
                                :required="false"
                                v-decimal/>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-3" v-if="!conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(true)">
        <v-icon color="success">mdi-file-check-outline</v-icon>
      </v-btn>
      <v-btn depressed color="grey lighten-3" v-if="conducted" :loading="getModalLoading" :disabled="isNew"
             @click="doConducted(false)">
        <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
      </v-btn>
      <v-btn depressed text color="secondary darken-1" :loading="getModalLoading"
             @click="crud_item" class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Провести та закрити
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_COMMISSIONING_ELEMENTS,
  UPDATE_DOCUMENT_COMMISSIONING_ELEMENTS,
  REMOVE_DOCUMENT_COMMISSIONING_ELEMENTS,
  CONDUCTED_DOCUMENT_COMMISSIONING_ELEMENTS,
  SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {subconto_type_select_setting} from '@/utils/accounting'
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account"
import commissioningAssetAPI from "@/utils/axios/accounting/documents/commissioning";
import {fixed_assets_group, format_date_from_python} from "@/utils/icons";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component_wto";

const modalDeleteId = 'commissioning_assets_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Document_CommissioningAssets",
  mixins: [ModalAccountingComponentMixin],
  components: {
    date_input: () => import("@/components/accounting/input/document_date_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    // textarea_input: () => import("@/components/accounting/input/document_text_area_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    select_input: () => import("@/components/accounting/input/document_select_input"),
  },
  data() {
    return {
      subconto_type_select_setting,
      fixed_assets_group,
      operation: this.item.operation || null,
      invest_chart_of_account_id: this.item.invest_chart_of_account_id || null,
      chart_of_account_id: this.item.chart_of_account_id || null,
      warehouse_id: this.item.warehouse_id || null,
      subdivision_id: this.item.subdivision_id || null,
      response_person_id: this.item.response_person_id || null,
      nomenclature_id: this.item.nomenclature_id || null,
      fixed_asset_id: this.item.fixed_asset_id || null,
      fixed_asset_group: this.item.fixed_asset_group || null,
      calc_amortisation: this.item.calc_amortisation || false,
      amortisation_chart_of_account_id: this.item.amortisation_chart_of_account_id || null,
      amortisation_calc_type: this.item.amortisation_calc_type || null,
      amortisation_calc_way: this.item.amortisation_calc_way || null,
      revaluation_chart_of_account_id: this.item.revaluation_chart_of_account_id || null,
      useful_life_period: this.item.useful_life_period || 0,
      liquidation_value: this.item.liquidation_value || 0,
      its_low_cost_item: this.item.its_low_cost_item || false,
      profit_chart_of_account_id: this.item.profit_chart_of_account_id || null,
      profit_chart_of_account_1_type: this.item.profit_chart_of_account_1_type || null,
      profit_chart_of_account_1_value: this.item.profit_chart_of_account_1_value || null,
      profit_chart_of_account_2_type: this.item.profit_chart_of_account_2_type || null,
      profit_chart_of_account_2_value: this.item.profit_chart_of_account_2_value || null,
      profit_chart_of_account_3_type: this.item.profit_chart_of_account_3_type || null,
      profit_chart_of_account_3_value: this.item.profit_chart_of_account_3_value || null,
      profit_sum: this.item.profit_sum || null,
    }
  },
  methods: {
    getUsefulLifePeriod(group) {
      if (!group) return this.useful_life_period = 0
      this.useful_life_period = (this.fixed_assets_group.find(i => i.value === group) || {}).months
    },
    getAssetGroupFromChart(chart_code) {
      if (!chart_code) this.fixed_asset_group = ''
      const group = (this.fixed_assets_group.find(i => i.value === `group_${chart_code}`) || {}).value || null
      this.fixed_asset_group = group
      if (group) {
        this.getUsefulLifePeriod(group)
      }
    },
    onInvestChartChange(payload) {
      let chart_code = ''
      if (payload) {
        if ('text' in payload && 'value' in payload) {
          chart_code = payload.type
        } else {
          chart_code = payload.code
        }
      }

      if (chart_code === '1521') {
        this.its_low_cost_item = false
        this.calc_amortisation = true
        this.amortisation_chart_of_account_id = (this.charts.find(item => item.type === '131') || {}).value
        this.revaluation_chart_of_account_id = (this.charts.find(item => item.type === '411') || {}).value
        this.chart_of_account_id = null
        this.amortisation_calc_type = 'straight'
      }
      if (chart_code === '1531') {
        this.its_low_cost_item = true
        this.calc_amortisation = false
        this.amortisation_chart_of_account_id = (this.charts.find(item => item.type === '1321') || {}).value
        this.revaluation_chart_of_account_id = (this.charts.find(item => item.type === '411') || {}).value
        this.chart_of_account_id = (this.charts.find(item => item.type === '1121') || {}).value
        this.amortisation_calc_type = 'fifty_to_fifty'
      }

      if (!chart_code) {
        this.its_low_cost_item = false
        this.calc_amortisation = true
        this.amortisation_chart_of_account_id = (this.charts.find(item => item.type === '131') || {}).value
        this.revaluation_chart_of_account_id = (this.charts.find(item => item.type === '411') || {}).value
        this.chart_of_account_id = null
        this.amortisation_calc_type = 'straight'
      }

    },
    onChartOfAccountIdChange(payload) {
      let chart_code = ''
      if (payload) {
        if ('text' in payload && 'value' in payload) {
          chart_code = payload.type
        } else {
          chart_code = payload.code
        }
      }
      if (!chart_code) {
        return
      }
      if (chart_code.substring(0, 2) === '10') {
        this.its_low_cost_item = false
        this.calc_amortisation = true
        this.invest_chart_of_account_id = (this.charts.find(item => item.type === '1521') || {}).value
        this.revaluation_chart_of_account_id = (this.charts.find(item => item.type === '411') || {}).value
        this.amortisation_chart_of_account_id = (this.charts.find(item => item.type === '131') || {}).value
        this.amortisation_calc_type = 'straight'
        this.getAssetGroupFromChart(chart_code)
      }
      if (chart_code.substring(0, 2) === '11') {
        this.its_low_cost_item = true
        this.calc_amortisation = true
        this.invest_chart_of_account_id = (this.charts.find(item => item.type === '1531') || {}).value
        this.revaluation_chart_of_account_id = (this.charts.find(item => item.type === '411') || {}).value
        this.amortisation_chart_of_account_id = (this.charts.find(item => item.type === '1321') || {}).value
        this.amortisation_calc_type = 'fifty_to_fifty'
        this.getAssetGroupFromChart(chart_code)
      }
    },
    onFixedAssetGroupChange(payload) {
      let asset_group = ''
      if (payload) {
        if ('text' in payload && 'value' in payload) {
          asset_group = payload.value
        } else {
          asset_group = payload.id
        }
      }
      if (asset_group) {
        this.getUsefulLifePeriod(asset_group, false)
      }
    },
    get_account_details(chart_id) {
      const chart = this.charts.find(c => c.value === chart_id) || {}

      if (!chart.value) {
        this.profit_chart_of_account_1_type = null
        this.profit_chart_of_account_2_type = null
        this.profit_chart_of_account_3_type = null
        this.profit_chart_of_account_1_value = null
        this.profit_chart_of_account_2_value = null
        this.profit_chart_of_account_3_value = null
        this.$nextTick(() => {
          this.checkDocument(false)
        })
      }

      chartOfAccountAPI.get_by_id({item_id: chart.value})
          .then(response => response.data)
          .then(data => {
            const details = data.details || []
            const obj_1 = details.find(i => i.order === 1)
            const obj_2 = details.find(i => i.order === 2)
            const obj_3 = details.find(i => i.order === 3)

            this.profit_chart_of_account_1_type = null
            this.profit_chart_of_account_2_type = null
            this.profit_chart_of_account_3_type = null
            this.profit_chart_of_account_1_value = null
            this.profit_chart_of_account_2_value = null
            this.profit_chart_of_account_3_value = null

            if (obj_1) {
              this.profit_chart_of_account_1_type = obj_1.detail_type
            }
            if (obj_2) {
              this.profit_chart_of_account_2_type = obj_2.detail_type
            }
            if (obj_3) {
              this.profit_chart_of_account_3_type = obj_3.detail_type
            }
          })
          .finally(() => {
            this.$nextTick(() => {
              this.checkDocument(false)
            })
          })
    },
    afterHeaderPayChartAccountChange(payload) {
      if ('text' in payload && 'value' in payload) {
        this.get_account_details(payload.value)
      } else {
        this.get_account_details(payload.id)
      }
    },
    afterHeaderDetail1Change() {
      this.$nextTick(() => {
        this.checkDocument(false)
      })
    },
    checkDocument(show=false) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'subdivision_id': {id: 'subdivision_id', text: 'Не заповнений підрозділ'},
        'operation': {id: 'operation', text: 'Не заповнена операція'},
        'response_person_id': {id: 'response_person_id', text: 'Не заповнена відповідальна особа'},
        'fixed_asset_id': {id: 'fixed_asset_id', text: 'Не заповнений основний засіб'},
        'chart_of_account_id': {id: 'chart_of_account_id', text: 'Не заповнений рахунок основного засобу'},
        'amortisation_chart_of_account_id': {id: 'amortisation_chart_of_account_id', text: 'Не заповнений рахунок амортизації'},
        'revaluation_chart_of_account_id': {id: 'revaluation_chart_of_account_id', text: 'Не заповнений рахунок дооцінок'},
        'fixed_asset_group': {id: 'fixed_asset_group', text: 'Не заповнена група ОС'},
        'amortisation_calc_type': {id: 'amortisation_calc_type', text: 'Не заповнений спосіб нарах. амортизації'},
        'amortisation_calc_way': {id: 'amortisation_calc_way', text: 'Не заповнене відображення витрат по аморт.'},
      }

      if (this.operation === 'fixed_asset') {
        header['nomenclature_id'] = {id: 'nomenclature_id', text: 'Не заповнена номенклатура'}
        header['invest_chart_of_account_id'] = {id: 'invest_chart_of_account_id', text: 'Не заповнений рахунок інвестицій'}
        header['warehouse_id'] = {id: 'warehouse_id', text: 'Не заповнений склад'}
        if (this.calc_amortisation) {
          header['useful_life_period'] = {id: 'useful_life_period', text: 'Не заповнений строк корисного використання'}
        }
      }

      if (this.operation === 'inventory') {
        header['profit_sum'] = {id: 'profit_sum', text: 'Заповніть вартість ОС'}
        header['profit_chart_of_account_id'] = {id: 'invest_chart_of_account_id', text: 'Не заповнений рахунок доходу'}
        if (this.profit_chart_of_account_1_type) {
          header['profit_chart_of_account_1_value'] = {id: 'profit_chart_of_account_1_value', text: 'Не заповнене Субконто 1'}
        }
        if (this.profit_chart_of_account_2_type) {
          header['profit_chart_of_account_2_value'] = {id: 'profit_chart_of_account_2_value', text: 'Не заповнене Субконто 2'}
        }
        if (this.profit_chart_of_account_3_type) {
          header['profit_chart_of_account_3_value'] = {id: 'profit_chart_of_account_3_value', text: 'Не заповнене Субконто 3'}
        }
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },

    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_COMMISSIONING_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, {text, color})
            }

          })
    },
    onOperationChange(payload) {
      const value = (payload || {}).value || null

      if (value) {
        if (value === 'inventory') {
          const chart = (this.charts.find(item => item.type === '746') || {})
          if (chart.value) {
            this.profit_chart_of_account_id = chart.value
            this.afterHeaderPayChartAccountChange(chart)
          } else {
            this.profit_chart_of_account_id = null
          }
        }
        if (value === 'fixed_asset') {
          this.profit_chart_of_account_id = null
          this.profit_chart_of_account_1_type = null
          this.profit_chart_of_account_2_type = null
          this.profit_chart_of_account_3_type = null
          this.profit_chart_of_account_1_value = null
          this.profit_chart_of_account_2_value = null
          this.profit_chart_of_account_3_value = null
        }
      }
    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.itemId = payload.id || null
      this.date = local_data.date || null
      this.number = local_data.number || null
      this.conducted = local_data.conducted || false
      this.operation = local_data.operation || null
      this.invest_chart_of_account_id = local_data.invest_chart_of_account_id || null
      this.chart_of_account_id = local_data.chart_of_account_id || null
      this.warehouse_id = local_data.warehouse_id || null
      this.subdivision_id = local_data.subdivision_id || null
      this.response_person_id = local_data.response_person_id || null
      this.nomenclature_id = local_data.nomenclature_id || null
      this.fixed_asset_id = local_data.fixed_asset_id || null
      this.fixed_asset_group = local_data.fixed_asset_group || null
      this.calc_amortisation = local_data.calc_amortisation || false
      this.amortisation_chart_of_account_id = local_data.amortisation_chart_of_account_id || null
      this.amortisation_calc_type = local_data.amortisation_calc_type || null
      this.amortisation_calc_way = local_data.amortisation_calc_way || null
      this.revaluation_chart_of_account_id = local_data.revaluation_chart_of_account_id || null
      this.useful_life_period = local_data.useful_life_period || 0
      this.liquidation_value = local_data.liquidation_value || 0
      this.its_low_cost_item = local_data.its_low_cost_item || false
      this.profit_chart_of_account_id = local_data.profit_chart_of_account_id || null
      this.profit_chart_of_account_1_type = local_data.profit_chart_of_account_1_type || null
      this.profit_chart_of_account_1_value = local_data.profit_chart_of_account_1_value || null
      this.profit_chart_of_account_2_type = local_data.profit_chart_of_account_2_type || null
      this.profit_chart_of_account_2_value = local_data.profit_chart_of_account_2_value || null
      this.profit_chart_of_account_3_type = local_data.profit_chart_of_account_3_type || null
      this.profit_chart_of_account_3_value = local_data.profit_chart_of_account_3_value || null
      this.profit_sum = local_data.profit_sum || null
    },
    fetch_data_by_id() {
      if (this.isNew) return

      commissioningAssetAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.date = data.date || null
            this.number = data.number || null
            this.conducted = data.conducted || false
            this.operation = data.operation || null
            this.invest_chart_of_account_id = data.invest_chart_of_account_id || null
            this.chart_of_account_id = data.chart_of_account_id || null
            this.warehouse_id = data.warehouse_id || null
            this.subdivision_id = data.subdivision_id || null
            this.response_person_id = data.response_person_id || null
            this.nomenclature_id = data.nomenclature_id || null
            this.fixed_asset_id = data.fixed_asset_id || null
            this.fixed_asset_group = data.fixed_asset_group || null
            this.calc_amortisation = data.calc_amortisation || false
            this.amortisation_chart_of_account_id = data.amortisation_chart_of_account_id || null
            this.amortisation_calc_type = data.amortisation_calc_type || null
            this.amortisation_calc_way = data.amortisation_calc_way || null
            this.revaluation_chart_of_account_id = data.revaluation_chart_of_account_id || null
            this.useful_life_period = data.useful_life_period || 0
            this.liquidation_value = data.liquidation_value || 0
            this.its_low_cost_item = data.its_low_cost_item || false
            this.profit_chart_of_account_id = data.profit_chart_of_account_id || null
            this.profit_chart_of_account_1_type = data.profit_chart_of_account_1_type || null
            this.profit_chart_of_account_1_value = data.profit_chart_of_account_1_value || null
            this.profit_chart_of_account_2_type = data.profit_chart_of_account_2_type || null
            this.profit_chart_of_account_2_value = data.profit_chart_of_account_2_value || null
            this.profit_chart_of_account_3_type = data.profit_chart_of_account_3_type || null
            this.profit_chart_of_account_3_value = data.profit_chart_of_account_3_value || null
          })
    },
    load_select_data() {
      return Promise.all([
        this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
      ])
    },

    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.date = this.item.date || null
      this.number = this.item.number || null
      this.conducted = this.item.conducted || null
      this.operation = this.item.operation || null
      this.contractor_id = this.item.contractor_id || null
      this.contract_id = this.item.contract_id || null
      this.bank_account_id = this.item.bank_account_id
      this.sum = this.item.sum || 0
      this.currency_id = this.item.currency || null
      this.comment = this.item.comment || null
      this.accrual_group = this.item.accrual_group || null
      this.order_number = this.item.order_number || null
      this.document_additional = this.item.document_additional || null
      this.document_reason = this.item.document_reason || null
      this.document_person_id = this.item.document_person_id || null
      this.cash_flow_item_id = this.item.cash_flow_item_id || null
      this.pay_chart_of_account_id = this.item.pay_chart_of_account_id || null
      this.chart_of_account_id = this.item.chart_of_account_id || null
      this.account_detail_1_value = this.item.account_detail_1_value
      this.account_detail_1_type = this.item.account_detail_1_type
      this.account_detail_2_value = this.item.account_detail_2_value
      this.account_detail_2_type = this.item.account_detail_2_type
      this.account_detail_3_value = this.item.account_detail_3_value
      this.account_detail_3_type = this.item.account_detail_3_type
      this.without_accounting = this.item.without_accounting || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документа введення в експлуатацію ОС № ${this.number} від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        date: this.date,
        operation: this.operation,
        invest_chart_of_account_id: this.invest_chart_of_account_id,
        chart_of_account_id: this.chart_of_account_id,
        warehouse_id: this.warehouse_id,
        subdivision_id: this.subdivision_id,
        response_person_id: this.response_person_id,
        nomenclature_id: this.nomenclature_id,
        fixed_asset_id: this.fixed_asset_id,
        fixed_asset_group: this.fixed_asset_group,
        calc_amortisation: this.calc_amortisation,
        amortisation_chart_of_account_id: this.amortisation_chart_of_account_id,
        amortisation_calc_type: this.amortisation_calc_type,
        amortisation_calc_way: this.amortisation_calc_way,
        revaluation_chart_of_account_id: this.revaluation_chart_of_account_id,
        useful_life_period: this.useful_life_period,
        liquidation_value: this.liquidation_value,
        its_low_cost_item: this.its_low_cost_item,
        profit_chart_of_account_id: this.profit_chart_of_account_id,
        profit_chart_of_account_1_type: this.profit_chart_of_account_1_type,
        profit_chart_of_account_1_value: this.profit_chart_of_account_1_value,
        profit_chart_of_account_2_type: this.profit_chart_of_account_2_type,
        profit_chart_of_account_2_value: this.profit_chart_of_account_2_value,
        profit_chart_of_account_3_type: this.profit_chart_of_account_3_type,
        profit_chart_of_account_3_value: this.profit_chart_of_account_3_value,
        profit_sum: this.profit_sum
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_COMMISSIONING_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.number = this.number
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_DOCUMENT_COMMISSIONING_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.load_select_data()
              this.checkDocument()
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_COMMISSIONING_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    }
  }
}
</script>
